@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;700&display=swap");

@layer utilities {
  @layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: "roobert-regularregular", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  font-family: "Libre Franklin", sans-serif;
}

.counter-container p {
  font-family: "ds-digi", Arial, sans-serif;
}

.hero {
  background: radial-gradient(
      126.96% 275.84% at 90.24% 16.36%,
      #b70569 0%,
      rgba(183, 5, 105, 0.12) 0.01%,
      rgba(183, 5, 105, 0.08) 19.27%,
      rgba(183, 5, 105, 0.165605) 30.73%,
      rgba(183, 5, 105, 0) 81.77%,
      rgba(183, 5, 105, 0) 100%
    ),
    url("./assets/images/2024/spring.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto, 200px;
}

@media screen and (min-width: 1024px) {
  .hero {
    background-size: auto, auto;
  }
}

/* .hero h1 {
  font-family: "grillmaster", Arial, sans-serif;
} */

.hero-img {
  background-image: url(./assets/images/hero-img-bg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 330px;
}

.speaker-bg {
  background-image: url(./assets/images/speaker-bg-frame.svg);
}

.modal-bg {
  background: linear-gradient(
      0deg,
      rgba(253, 192, 227, 0.4) 0%,
      rgba(253, 192, 227, 0.4) 100%
    ),
    #fff;
}

.speaker-name {
  font-family: "grillmaster", Arial, sans-serif;
}

@font-face {
  font-family: "roobert-regularregular";
  src: url("./assets/fonts/roobert/roobert-regular-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/roobert/roobert-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ds-digi";
  src: url("./assets/fonts/digital_number/ds-digi-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/digital_number/ds-digi-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grillmaster";
  src: url("./assets/fonts/grill_master/Fontspring-DEMO-grillmaster_expanded_007_extra_bold.otf")
    format("opentype");
  font-weight: bolder;
  font-style: normal;
}

/* font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal; */

/* font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal; */
